import React from "react";
import Seo from "../../components/seo";
import LocalDevDashboard from "../../components/Dashboard/localDev";

const LocalDevPage = () => (
  <>
    <Seo title="Local Dev Dashboard" />
    <LocalDevDashboard />
  </>
);

export default LocalDevPage;
