import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChart = ({ data }) => {
  const options = {
    plugins: {
      title: {
        display: false,
      },
      datalabels: false,
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value =
              tooltipItem.raw ||
              tooltipItem.formattedValue ||
              tooltipItem.value;

            return value + " projects";
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return <Bar options={options} data={data} />;
};

export default StackedBarChart;
