import { adminContextStore } from "../context/store/createStore";
import { SET_PRODUCTION_LIST } from "../context/actions/production";
import { getProjectList } from "../httpRequests/projectList";

const sortProjectsById = (data) => {
  return data.sort((a, b) =>
    a.projectId.toLowerCase().localeCompare(b.projectId.toLowerCase())
  );
};

const filterDevProjects = (data) => {
  return data.filter((i) => i.projectId.startsWith("ENG")) || [];
};

export default async (projectYear, stage) => {
  const { dispatch } = adminContextStore;

  try {
    dispatch({
      type: SET_PRODUCTION_LIST,
      payload: { loading: true },
    });

    const response = await getProjectList(projectYear, stage);

    // Sort and filter projects
    const sortedData = sortProjectsById(response.data);
    const devProjects = filterDevProjects(sortedData);

    dispatch({
      type: SET_PRODUCTION_LIST,
      payload: {
        loading: false,
        productionList: response,
        devData: devProjects,
      },
    });
  } catch (error) {
    dispatch({
      type: SET_PRODUCTION_LIST,
      payload: { loading: false },
    });
  }
};
