import React from "react";
import { Link } from "gatsby";
const TableComp = ({ header, data, link, task }) => {
  return (
    <table className="task">
      <thead>
        <tr>
          {header.map((h, i) => (
            <th key={i}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 &&
          data.map((i, index) => (
            <tr key={index}>
              <td>
                <Link to={`${link}${i.projectId}`}>{i.projectName}</Link>
              </td>
              <td>{task ? i.sdm : i.stage}</td>
              {task && <td>{i.finishDate}</td>}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableComp;
