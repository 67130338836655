import axios from "axios";

export const getProjectList = (projectYear, stage) =>
  axios.get(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/projects/?projectYear=${projectYear}&stage=${stage}`
  );

export const getProjectDetail = (id) =>
  axios.get(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/projects/${id}`);

export const addNewProject = (params) =>
  axios.post(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/projects/`,
    params
  );

export const patchProjectDetail = (id, params) =>
  axios.patch(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/projects/${id}`,
    params
  );
