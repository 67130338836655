const DEV_TEAMS = [
  {
    teamName: "Enterprise API",
    areas: "Core API / Integrations",
    developers: 29,
    sdm: "Art Karusenee (Art)",
    team: 'eci',
    board: 'FFTI, EAI, ECI, EIM',
    link: '/teams/local-dev?team=eci'
  },
  {
    teamName: "Customer",
    areas: "Identity / Loyalty / B-Team / Product & Fulfilment",
    developers: 12,
    sdm: "Mongkol Puengpipattrakul (Peung)",
    team: 'customer',
    board: 'LOT, CID, CLT, PF',
    link: '/teams/local-dev?team=customer'
  },
  {
    teamName: "Mobile",
    areas: "IOS / Android / Web / BFF",
    developers: 15,
    sdm: "Atirat Wajanasoontorn (Ton)",
    team: 'mobile',
    board: 'CLM, UX',
    link: '/teams/local-dev?team=mobile'
  },
  {
    teamName: "HO Dev",
    areas: "Full Stack / Workflow",
    developers: 10,
    sdm: "Peerasak Rattanamanee (Pee)",
    team: 'full-stack',
    board: 'LCD, SEAWF, NSCH',
    link: '/teams/local-dev?team=full-stack'
  },
  {
    teamName: "Store",
    areas: "CMA, Checkout, Label",
    developers: 9,
    sdm: "Sattha Puangput (Jong)",
    team: 'store',
    board: 'LSE, SLM',
    link: '/teams/local-dev?team=store'
  },
  {
    teamName: "Automation",
    areas: "RPA",
    developers: 4,
    sdm: "Parama Paramagul (Zie)",
    team: 'rpa',
    board: 'RPA',
    link: '/teams/local-dev?team=rpa'
  },
  {
    teamName: "UXUI",
    areas: "O2O / CLM / Store-CMA / Mall",
    developers: 4,
    sdm: "Thanchanok Lekviriyakul (Un)",
    team: 'uxui',
    board: 'UXUI',
    link: '/teams/local-dev?team=uxui'
  },
];

export default DEV_TEAMS;
