const DEV_SKILLS = [
  {
    skillName: "VueJS",
    primary: "13",
    secondary: "8",
  },
  {
    skillName: "ReactJS",
    primary: "11",
    secondary: "16",
  },
  {
    skillName: "Angular",
    primary: "5",
    secondary: "9",
  },
  {
    skillName: "Swift-iOS",
    primary: "5",
    secondary: "0",
  },
  {
    skillName: "Kotlin-Andriod",
    primary: "7",
    secondary: "4",
  },
  {
    skillName: "Flutter",
    primary: "4",
    secondary: "1",
  },
  {
    skillName: "Golang",
    primary: "9",
    secondary: "6",
  },
  {
    skillName: "Java/Kotlin",
    primary: "21",
    secondary: "11",
  },
  {
    skillName: "NodeJS",
    primary: "16",
    secondary: "14",
  },
  {
    skillName: "Python",
    primary: "5",
    secondary: "4",
  },
  {
    skillName: "C#",
    primary: "3",
    secondary: "5",
  },
  {
    skillName: "MySQL",
    primary: "9",
    secondary: "7",
  },
  {
    skillName: "MSSQL",
    primary: "13",
    secondary: "10",
  },
  {
    skillName: "Oracle",
    primary: "7",
    secondary: "5",
  },
  {
    skillName: "PostgreSQL",
    primary: "24",
    secondary: "17",
  },
  {
    skillName: "MariaDB",
    primary: "1",
    secondary: "1",
  },
  {
    skillName: "Redis",
    primary: "4",
    secondary: "22",
  },
  {
    skillName: "MongoDB",
    primary: "32",
    secondary: "7",
  },
  {
    skillName: "CouchDB",
    primary: "0",
    secondary: "0",
  },
  {
    skillName: "Couchbase DB",
    primary: "5",
    secondary: "2",
  },
  {
    skillName: "Apache Cassandra",
    primary: "0",
    secondary: "0",
  },
  {
    skillName: "RabbitMQ",
    primary: "2",
    secondary: "5",
  },
  {
    skillName: "Kafka",
    primary: "38",
    secondary: "4",
  },
  {
    skillName: "ActiveMQ",
    primary: "0",
    secondary: "0",
  },
  {
    skillName: "RocketMQ",
    primary: "0",
    secondary: "0",
  },
  {
    skillName: "Kafka Connector",
    primary: "0",
    secondary: "6",
  },
  {
    skillName: "GCP",
    primary: "2",
    secondary: "9",
  },
  {
    skillName: "Azure",
    primary: "2",
    secondary: "5",
  },
  {
    skillName: "AWS",
    primary: "28",
    secondary: "5",
  },
  {
    skillName: "Alibaba",
    primary: "0",
    secondary: "0",
  },
  {
    skillName: "Power Automate",
    primary: "1",
    secondary: "3",
  },
  {
    skillName: "Robot Framework",
    primary: "3",
    secondary: "4",
  },
  {
    skillName: "AI",
    primary: "0",
    secondary: "0",
  },
  {
    skillName: "Performance Test",
    primary: "12",
    secondary: "0",
  },
];

export default DEV_SKILLS;
